import React, { createContext, useEffect, useState } from 'react';
import { globalHistory } from '@reach/router';

export const AppContext = createContext<{
  locale: 'en' | 'ja-jp' | 'zh-cn' | string;
  prefix: string;
  options: object;
}>({ locale: 'en', prefix: '/', options: {} });

export const AppProvider = ({ children, pathname = '' }: any) => {
  const [locale, setLocale] = useState('en');
  const [prefix, setPrefix] = useState('/');
  const [options, setOptions] = useState({});

  useEffect(()=>{
    if (typeof window !== 'undefined') {
      try {
        const winLocale = window.location.pathname.split('/').filter(Boolean)[0];
        if (['ja-jp', 'zh-cn'].includes(winLocale)) {
          setLocale(winLocale);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },[])

  const setLocaleByPathname = (pathname = '') => {
    if (pathname === '/ja-jp' || pathname.startsWith('/ja-jp/')) {
      setLocale('ja-jp');
    } else if (pathname === '/zh-cn' ||  pathname.startsWith('/zh-cn/')) {
      setLocale('zh-cn');
    } else {
      setLocale('en');
    }
  };

  useEffect(() => {
    setLocaleByPathname(pathname);
  }, [pathname]);

  useEffect(() => {
    return globalHistory.listen((props) => {
      if (props.action === 'PUSH' || props.action === 'POP') setLocaleByPathname(props.location.pathname);
    });
  }, []);

  // Get correct options page
  useEffect(() => {
    fetch(`https://opengammacms.wpengine.com/wp-json/together/options?lang=${locale}&v=8`)
      .then((res) => res.json())
      .then((data) => {
        setOptions(data);
      });
  }, [locale]);

  // Get correct options page
  useEffect(() => {
    setPrefix(locale === 'en' ? '/' : `/${locale}`);
  }, [locale]);

  return (
    <AppContext.Provider
      value={{
        locale,
        prefix,
        options,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
