import Cookies from 'universal-cookie';

export default function getBrowserLanguage() {
  if (typeof window === 'undefined') return;

  try {
    const cookies = new Cookies();
    const cookeLocale = cookies.get('locale');

    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

    if (cookeLocale?.length > 0) lang = cookeLocale;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1) shortLang = shortLang.split('-')[0];
    if (shortLang.indexOf('_') !== -1) shortLang = shortLang.split('_')[0];

    return shortLang;
  } catch (error) {
    console.warn(error);
    return 'en';
  }
}
