import '~/assets/scss/styles.scss';

import React from 'react';
import getBrowserLanguage from './src/utils/getBrowserLanguage';
// import {  navigate } from 'gatsby';
// import { L: } from '@reach/router';
import { AppProvider } from './src/context';

export const wrapRootElement = ({ element, pathname }) => {
  return <AppProvider pathname={window.location.pathname}>{element}</AppProvider>;
};

function redirectToLocaleVersion() {
  if (typeof window === 'undefined') return;

  // Define supported languages with their respective mappings.
  const supportedLangs = {
    en: '',
    ja: 'ja-jp',
    zh: 'zh-cn',
  };

  // Construct the new path with the localized language.
  const constructNewPath = (lang) => {
    const currentPath = window.location.pathname;
    const langPrefix = supportedLangs[lang];
    // Remove any existing language prefix from the path.
    const basePath = currentPath.replace(/\/(zh-cn|ja-jp)/ig, '');
    return lang === 'en' ? basePath : `/`+langPrefix + basePath;
  };

  const isCrawler = () => {
    try {
      const userAgent = typeof window !== 'undefined' ? navigator.userAgent : '';
      const knownCrawlers = ['bot', 'Googlebot', 'Bingbot', 'Slurp', 'DuckDuckBot', 'Baiduspider', 'YandexBot', 'Sogou'];
      return knownCrawlers.some(crawler => userAgent.includes(crawler));
    } catch (e) {
      return false;
    }
  };

  const isLocalhost = () => {
    return window?.location?.hostname === 'localhost';
  };

  // Main redirection logic.
  const browserLang = getBrowserLanguage();

  if (supportedLangs[browserLang] !== undefined && !isCrawler() && !isLocalhost()) {
    const newPath = constructNewPath(browserLang);
    if (newPath !== window.location.pathname) {
      window.location.pathname = newPath;
    }
  }
}

export const onClientEntry = ( ) => {
  redirectToLocaleVersion();
};
